
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import PromotionCodeService from "@/core/services/PromotionCodeService";
import PromotionCode from "@/models/IPromotionCode";
import { roles } from "@/store/stateless/store";

interface PromotionCodeBodyParam {
  per_page: string;
  expired: any;
  page: any;
}

export default defineComponent({
  name: "users-list",
  components: {},
  data() {
    return {
      data: [],
    };
  },
  setup() {
    // let customers_obj = ref([]);

    const store = useStore();
    let bodyParameters = {} as PromotionCodeBodyParam;
    let promotionCodeList = ref<PromotionCode>();
    const loading = ref(true);
    const footerData = ref({
    from: 0,
    to: 0,
    total: 0
})

    bodyParameters = {
      per_page: "50",
      expired: 0,
      page:"1"
    };

    onMounted(() => {
      if(!roles.promoCodes.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Promotion Codes", []);
      // API Action to get promo code list
      getPromotionCodeList(bodyParameters);
    });

    const getPromotionCodeList = (bodyParameters, index = 1) => {
      Promise.all([
        PromotionCodeService.getPromotionCodeList(bodyParameters, index),
      ]).then((data) => {
        loading.value = false;
        promotionCodeList.value = store.getters.getPromotionCode;
        const promoPages = store.getters.getPromotionCodePaginationObj;
        footerData.value.from = promoPages.from
        footerData.value.to = promoPages.to
        footerData.value.total = promoPages.total
      });
    };

    const onChangeStatus = (value) => {
      bodyParameters.expired = value;
      getPromotionCodeList(bodyParameters);
    };

    // let promotionCodeList = computed(() => {
    //   return store.getters.promotionCodeList;
    // });

    const getPaginationUrl = (index) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      let pagenationUrl = index.split("=");
      getPromotionCodeList(bodyParameters, pagenationUrl[1]);
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      getPromotionCodeList(bodyParameters);
    };

    const paginatioObject = computed(() => {
      return store.getters.PromotionCodeModule.paginationObj;
    });

    function changePagination(e: any) {
      bodyParameters.page = e;
      getPromotionCodeList(bodyParameters);

    }


    return {
      loading,
      roles,
      bodyParameters,
      getPromotionCodeList,
      promotionCodeList,
      onChangeStatus,
      onPerPageChange,
      getPaginationUrl,
      paginatioObject,
      footerData,
      changePagination
    };
  },
});
